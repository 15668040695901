<template>
  <ion-fab
    class="memberCardBtn"
    vertical="bottom"
    horizontal="end"
    slot="fixed"
    v-show="$store.state.form.token&&$store.state.appVersion"
  >
    <ion-fab-button class="cardBtn" @click="goWallet()">
      <img src="@/assets/memberCard.png" />
    </ion-fab-button>
  </ion-fab>
</template>

<script>
import { IonFabButton, IonFab } from "@ionic/vue";
export default {
  name: "wallet-btn",
  components: { IonFabButton, IonFab },
  props: {},
  data() {
    return {};
  },

  computed: {},

  methods: {
    goWallet() {
      this.$router.push({ path: "/wallet" });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
