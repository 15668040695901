<template>
  <ion-page id="more-page">
    <ion-content :fullscreen="true">
      <div :class="{ 'fixed-top': !$store.state.isPC }">
        <div class="statusBar" ref="statusBar" v-if="!$store.state.isPC"></div>
        <div class="home-bar" ref="topMenu" v-if="!$store.state.isPC">
          <div class="bar-title" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }">{{ $t("more.More") }}</div>
        </div>
      </div>
      <div class="pc-box margin-top-0" :class="{ 'padding-top-117': !$store.state.isPC }">
        <div class="pc-min-content-pct">
          <!-- <div class="user-info" v-if="$store.state.isPC">
            <div v-if="$store.state.form.token" class="user-icon Bold main-color">
              <img
                :src="$store.state.form.userInfo.icon? $store.state.form.userInfo.icon : defaultIcon"
              />
              <span class="name">{{ $store.state.form.userInfo.userName }}</span>
            </div>
            <div class="edit-profile Bold main-color" v-if="$store.state.form.token">
              <img
                class="edit pointer"
                src="../assets/edit.png"
                @click="getEditProfile"
              />
              <span>Edit Profile</span>
            </div>
          </div> -->
          <div class="me-content" :style="$store.state.isPC ? 'margin-top: 100px' : ''">
            <!-- <div class="flex-box" v-if="$store.state.isPC && $store.state.form.token">
              <div class="center-item invite no-bottom-boder" @click="getReferral">
                <img src="../assets/friends.png">
                <span class="main-color Bold font-16">Invite Friends</span>
              </div>
              <div class="center-item logout no-bottom-boder" @click="logoutEvent">
                <img src="../assets/logout.png">
                <span class="main-color Bold font-16">Logout</span>
              </div>
            </div>
            <div class="border-6" v-if="$store.state.form.token"></div> -->
            <div class="Medium" :class="{ 'flex-box': $store.state.isPC }">
              <!-- <div
                class="center-item"
                v-if="$store.state.form.token"
                @click="goChangePassword"
              >
                <div class="label font-16 main-color Bold">Change password</div>
              </div> -->
              <div class="center-item" @click="goCommunity" v-if="$store.state.form.token">
                <div class="label font-18 main-color Bold pc-font-bold">{{ $t("more.Community") }}</div>
              </div>
              <div class="center-item" @click="goLocator" v-if="!$store.state.isPC">
                <div class="label font-18 main-color Bold">{{ $t("more.StoreLocator") }}</div>
              </div>
              <div class="center-item" v-if="!$store.state.isPC" @click="goUserGuide">
                <div class="label font-18 main-color Bold">{{ $t("more.UserGuide") }}</div>
              </div>
              <div class="center-item" @click="goAboutUs">
                <div class="label font-18 pc-font-bold main-color Bold">{{ $t("more.AboutUs") }}</div>
              </div>
              <div class="center-item" @click="goFaqs">
                <div class="label font-18 pc-font-bold main-color Bold">{{ $t("more.FAQs") }}</div>
              </div>
              <div class="center-item" @click="goTC">
                <div class="label font-18 main-color pc-font-bold Bold">{{ $t("more.TermsConditions") }}</div>
              </div>
              <div class="center-item" @click="goPrivacy">
                <div class="label font-18 main-color pc-font-bold Bold">{{ $t("more.PrivacyPolicy") }}</div>
              </div>
              <div class="center-item" :class="{ 'no-border': !$store.state.isPC }" @click="goContactUs">
                <div class="label font-18 main-color pc-font-bold Bold">{{ $t("more.ContactUs") }}</div>
              </div>
              <div class="font-12 center" v-if="$store.state.appVersion">App v{{ $store.state.appVersion }} Web v.{{ gitVersion }}</div>

              <div class="font-12 center" v-if="!$store.state.appVersion && !$store.state.isPC">Web v.{{ gitVersion }}</div>
            </div>
          </div>
          <div class="font-12" style="text-align: right; color: #fff; padding-top: 20px" v-if="!$store.state.appVersion && $store.state.isPC">Web v.{{ gitVersion }}</div>
        </div>
      </div>
      <wallet-btn />
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import walletBtn from "@/components/walletBtn.vue";
import config from "../config";
export default {
  name: "MorePage",
  mixins: [config.globalMixin],
  data() {
    return {
      gitVersion: process.env.VERSION,
    };
  },
  components: { IonContent, IonPage, walletBtn },

  methods: {
    goChangePassword() {
      this.$router.push({
        path: "/changePassword",
      });
    },
    goCommunity() {
      this.sendGAEvent("Click", "More", "Community");

      if (this.$store.state.bannerDetail.id) {
        this.openInAppBrowser(this.$store.state.bannerDetail);
      } else {
        this.$router.push({
          path: "/community",
        });
      }
    },
    goLocator() {
      this.sendGAEvent("Click", "More", "Store Locator");

      this.$router.push({
        path: "/storeLocator",
      });
    },
    goUserGuide() {
      this.sendGAEvent("Click", "More", "User Guide");

      this.$router.push({
        path: "/userGuide",
        query: {
          isMore: true,
        },
      });
    },
    goAboutUs() {
      this.sendGAEvent("Click", "More", "About Us");

      this.$router.push({
        path: "/aboutUs",
      });
    },
    goFaqs() {
      this.sendGAEvent("Click", "More", "FAQs");
      this.$router.push({
        path: "/faqs",
      });
    },
    goTC() {
      this.sendGAEvent("Click", "More", "Terms & conditions");
      this.$router.push({
        path: "/Terms",
      });
    },
    goPrivacy() {
      this.sendGAEvent("Click", "More", "Privacy policy");

      this.$router.push({
        path: "/privacy",
      });
    },
    goContactUs() {
      this.sendGAEvent("Click", "More", "Contact Us");

      this.$router.push({
        path: "/contactUs",
      });
    },
    getEditProfile() {
      this.$router.push({
        path: "/editProfile",
      });
    },
    getReferral() {
      this.$router.push({
        path: "/referral",
      });
    },
    async logoutEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.logout;
      await this.$store.dispatch("apiEvent", objdata);
      this.$store.state.form.token = "";
      this.setStorageEvent();
      this.$router.push({
        path: "/main/landing",
      });
    },
    async getHomePageEvent() {
      const objdata = this.getAuthObj();
      objdata.apiUrl = this.apiConfig.listHomePage;
      const data = await this.$store.dispatch("apiGetEvent", objdata);
      if (!data) {
        return;
      }
      if (data == this.TIMEOUT_CODE) {
        this.timeoutEvent();
        return;
      }
      this.sendGAEvent("Click", "More", "Go Home");

      data.data.result.records.forEach(item => {
        if (item.bannerType == 4) {
          this.$store.state.bannerDetail = item;
        }
      });
    },
  },
  ionViewDidEnter() {
    this.clickTabEvent("more");
    this.$store.state.exitApp = false;
    if (!this.$store.state.bannerDetail.id) {
      this.getHomePageEvent();
    }
    // setTimeout(() => {
    //   let hi1 = $("#me-page").attr("class").search("ion-page-hidden") > -1 || false;
    // if (this.$route.path == "/main/more") {
    //   $("#offers-page").addClass("ion-page-hidden");
    //   $("#offers-page").css("zIndex", "100");
    //   $("#landingPage").addClass("ion-page-hidden");
    //   $("#landingPage").css("zIndex", "100");
    //   $("#me-page").addClass("ion-page-hidden");
    //   $("#me-page").css("zIndex", "100");
    //   $("#more-page").removeClass("ion-page-hidden");
    //   $("#more-page").css("zIndex", "101");
    // }
    // }, 100);
    this.sendGAPageView("More");
  },
  ionViewWillEnter() {
    // console.log("more", new Date().getTime());
  },
};
</script>
